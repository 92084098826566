@import "common"
body
  background: url("../images/bg.jpg") repeat
.top_head
  z-index: 99
  position: absolute
  left: 0px
  top: 0px
  width: 100%
  border-bottom: 1px solid rgba(255,255,255,0.4)
  .logo
    display: block
    float: left
    margin: 17px 30px
  .top_menu
    float: left
    margin-left: 50px
    height: 90px
    line-height: 90px
    ul
      li
        float: left
        padding: 0px 20px
        font-size: 16px
        position: relative
        color: #fff
        span
          position: absolute
          width: 30%
          height: 2px
          left: 35%
          bottom: 25px
          background: #ffcc00
          display: none
        &.on,&:hover
          background: url("../images/top_menu_bg.png") repeat-x
          color: #e1a76a
          span
            display: block
  .search
    position: absolute
    right: 0
    top: 30px
    .change
      display: inline-block
      vertical-align: top
      font-size: 15px
      color: #999999
      margin-left: 10px
      .active
        color: #c18a50

.banner-wrap
  width: 100%
  margin: 0 auto
  .banner
    width: 100%
    overflow: hidden
    position: relative
    img
      vertical-align: middle
      width: 100%
      animation: scale_b 25s linear infinite alternate
  .banner-tab
    position: absolute
    left: 0px
    bottom: 15px
    width: 100%
    display: flex
    li
      flex: 1
      color: #cecece
      height: 57px
      margin: 0 3px
      font-size: 20px
      cursor: pointer
      line-height: 57px
      background: rgba(0,0,0,0.5)
      border-radius: 4px
      color: #cecece
      text-align: center



.main_box
  overflow: hidden
  padding: 10px 0px
  position: relative
  .box1 + .box1
    margin-left: 15px
  .box1
    float: left
    width: 460px

    position: relative

    .tit
      height: 45px
      font-weight: normal
      font-size: 15px
      color: #ffffff
      line-height: 45px
      .more
        cursor: pointer
        float: right
        margin-top: 3px
      span
        margin-left: 7px
        vertical-align: bottom
        line-height: 39px
        font-size: 12px
        color: #5d6066
    .cont
      background: #62646d
      display: flex
      flex-wrap: wrap
      padding: 10px
      width: 100%
      box-sizing: border-box
      box-shadow: 0px 0px 20px rgba(0,0,0,0.7)
      .list-item
        display: block
        margin-bottom: 9px
        border-radius: 5px
        overflow: hidden
        &:last-child
          margin-bottom: 0
        img
          display: block
      .txt
        flex: 1
        text-indent: 2em
        padding-left: 15px
        font-size: 14px
        color: #a1a3a8
        line-height: 25px
        min-width: 0
        h3
          padding: 5px 0
          color: #fff
          font-size: 16px
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
      @include list-ul

.footer
  position: relative
  z-index: 1
  .txt
    float: left
    overflow: hidden
    width: 75%
    font-size: 13px
    padding: 15px 0 10px 0
    ul
      overflow: hidden
      li
        float: left
        padding: 0 10px
        border-right: 1px solid #666666
        line-height: 14px
        a
          color: #868686
        &:hover a
          color: #E7811F
    p
      color: #868686
      text-align: left
      line-height: 30px
      padding-left: 10px
      width: 100%
  .select_box
    position: relative
    float: right
    width: 195px
    margin-top: 16px
    height: 33px
    color: #868686
    border: 1px solid #727374
    line-height: 35px
    font-size: 14px
    padding-left: 15px
    border-radius: 4px
    i
      border: 5px solid transparent
      border-top-color: #000000
      position: absolute
      top: 15px
      right: 10px
    ul
      position: absolute
      width: 210px
      color: #e2e2e2
      border: 1px solid #727374
      left: -1px
      bottom: 35px
      background: #62646d
      max-height: 120px
      overflow-y: auto
      li
        cursor: pointer
        padding-left: 15px
        &:hover
          cursor: pointer
          padding-left: 15px
          background: rgba(0,0,0,0.3)
.news-wrap
  box-sizing: border-box
  .txt_box
    float: left
    width: 445px
    padding-left: 20px
    position: relative
    .title
      width: 100%
      height: 50px
      font-weight: normal
      font-size: 15px
      color: #ffffff
      line-height: 50px
      background: #24252a
      .name
        display: inline-block
        background: #946c43
        color: #fff
        padding: 0 15px
        font-size: 16px
        span
          margin-left: 7px
          vertical-align: bottom
          line-height: 43px
          font-size: 12px
      .more
        cursor: pointer
        float: right
        margin-top: 3px
        margin-right: 15px
    .top
      padding: 25px 0
      h3
        color: #d49d64
        font-size: 16px
        padding-bottom: 15px
        text-align: center
        font-weight: 600
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
      p
        text-indent: 2em
        line-height: 22px
        color: #b7b7b7
        font-size: 13px
        overflow : hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
    @include list-ul
    ul li
      height: 35px
      line-height: 35px

  .slideBox
    width: 693px
    height: 430px
    overflow: hidden
    position: relative
    float: left
    .hd
      height: 15px
      overflow: hidden
      position: absolute
      right: 8px
      bottom: 50px
      z-index: 1
      ul
        overflow: hidden
        zoom: 1
        float: left
        li
          float: left
          margin-right: 4px
          color: #FFFFFF
          width: 10px
          height: 10px
          border-radius: 50%
          background: #fff
          line-height: 14px
          text-align: center
          cursor: pointer
          &.on
            background: #e10b0b
    .bd
      position: relative
      height: 100%
      z-index: 0
      li
        zoom: 1
        vertical-align: middle
        position: relative
      img
        width: 693px
        height: 430px
        display: block
      span
        position: absolute
        bottom: 0px
        left: 0
        height: 40px
        width: calc(100% - 95px)
        padding-left: 15px
        padding-right: 80px
        line-height: 40px
        font-size: 16px
        background: rgba(0,0,0,0.5)
        color: #FFFFFF
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
      small
        position: absolute
        bottom: 0px
        right: 0
        height: 40px
        width: 70px
        text-align: center
        line-height: 40px
        font-size: 16px
        color: #FFFFFF
  .news-tab
    margin-top: 10px
    .tab-wrap
      background: #323337
      margin-bottom: 2px
      font-size: 0
      li
        display: inline-block
        position: relative
        width: 155px
        height: 40px
        line-height: 40px
        background: #323337
        color: #f3f3f3
        text-align: center
        border-right: 1px solid #24252a
        margin: 5px 0
        font-size: 16px
        &:last-child
          border-right: none
        &.active
          height: 50px
          line-height: 50px
          margin: 0
          border-right: none
          background: #946c43
    .list-wrap
      .ul_list
        display: none
        li
          display: flex
          background: #323337
          padding: 15px 30px
          margin-bottom: 10px
          img
            width: 154px
            margin-right: 15px
          .item
            flex: 1
            h3
              color: #f7f7f7
              font-size: 16px
              font-weight: normal
              padding: 5px 0 15px 0
            .p
              text-indent: 2em
              color: #b2b2b2
              line-height: 27px
              font-size: 14px
.about-wrap
  .about1
    .title
      background: #323337
      padding: 0 40px 0 60px
      position: relative
      color: #f0b475
      line-height: 60px
      font-size: 17px
      margin-bottom: 2px
      &:after
        content: ""
        position: absolute
        left: 43px
        top: 26px
        width: 6px
        height: 6px
        border: 1px solid #f0b475
        transform: rotate(45deg)
      p
        float: right
        color: #cac9c9
        font-size: 14px
        a
          color: #cac9c9
    .cont_main
      background: #323337
      padding-bottom: 50px
      .main_img
        display: block
        margin: 0 auto
        padding: 20px 0
      .tip
        margin-left: 40px
        position: relative
        margin-bottom: 20px
        span
          position: absolute
          color: #fff
          left: 40px
          top: 14px
      .t-item
        line-height: 30px
        width: 1000px
        margin: 0 auto
        text-align: center
        font-size: 16px
        color: #cac9c9
        padding: 20px 0 30px 0
        .min-tit
          display: inline-block
          position: relative
          margin-top: 20px
          line-height: 35px
          font-size: 17px
          color: #dedfe0
          .l
            position: absolute
            left: -20px
            top: 12px
            width: 13px
            height: 12px
            background: url("../images/icon5.png") no-repeat
          .r
            position: absolute
            right: -20px
            top: 12px
            width: 13px
            height: 12px
            background: url("../images/icon5.png") no-repeat
        .max-tit
          line-height: 35px
          font-size: 24px
          color: #c18a50
          padding-bottom: 20px

      .text
        line-height: 30px
        padding-bottom: 40px
        font-size: 15px
        width: 1120px
        margin: 0 auto
        color: #cac9c9
      .cont-info
        margin-left: 40px
        line-height: 30px
        font-size: 14px
        color: #cac9c9
        span
          display: inline-block
          vertical-align: top
          margin-right: 8px
          margin-top: 5px
          width: 20px
          height: 20px
          background: url("../images/icon3.png") no-repeat
          &.s1
            background-position: 0px 0px
          &.s2
            background-position: 0px -31px
          &.s3
            background-position: 0px -61px
  .about3
    .title
      background: #323337
      padding: 0 40px 0 60px
      position: relative
      color: #f0b475
      line-height: 60px
      font-size: 17px
      margin-bottom: 2px
      &:after
        content: ""
        position: absolute
        left: 43px
        top: 26px
        width: 6px
        height: 6px
        border: 1px solid #f0b475
        transform: rotate(45deg)
      p
        float: right
        color: #cac9c9
        font-size: 14px
        a
          color: #cac9c9
    .dt_menu
      float: left
      margin: 12px 0
      width: 230px
      overflow: hidden
      .dt_menu_tit
        width: 100%
        height: 46px
        font-size: 16px
        color: #fff
        font-weight: 400
        line-height: 46px
        background: #966e43
        text-align: center
        position: relative
      ul
        background: #323337
        padding: 20px 30px
        li
          position: relative
          width: 100%
          height: 37px
          text-align: center
          line-height: 37px
          font-size: 15px
          color: #d4d4d4
          cursor: pointer
          border-radius: 4px
          -webkit-border-radius: 4px
          -moz-border-radius: 4px
          -ms-border-radius: 4px
          &.on,&:hover
            background: #37383c
            color: #fff
          &.on:after
            content: ""
            position: absolute
            left: 38px
            top: 14px
            width: 7px
            height: 7px
            background: #946c43
            transform: rotate(45deg)
      .inline-search
        background: #323337
        padding: 30px
        input
          width: calc(100% - 30px)
          background: #48494d
          border: none
          color: #797979
          font-size: 14px
          padding: 5px 15px
          height: 24px
          line-height: 24px
          margin-bottom: 7px
        button
          width: 100%
          background: #2a2b2e
          border: none
          color: #b0b0b2
          font-size: 14px
          cursor: pointer
          height: 36px
          line-height: 36px
    .cont-right
      float: left
      width: calc(100% - 245px)
      margin: 12px 0 12px 15px
      background: #323337
      padding: 50px
      box-sizing: border-box
      .listimg-wrap
        .inline-tit
          position: relative
          height: 3px
          background: #38393d
          margin: 60px 60px 40px 60px
          span
            position: absolute
            left: 50%
            top: -19px
            color: #f0b475
            font-size: 18px
            padding: 10px 45px
            background: #38393d
            transform: translateX(-50%)
      &>p
        position: relative
        color: #d4d4d4
        font-size: 16px
        line-height: 22px
        padding: 20px 60px
      &>p:hover
        background: #37393d
      &>p:hover:after
        content: ""
        position: absolute
        left: 25px
        top: 50%
        width: 7px
        height: 7px
        background: #946c43
        transform: rotate(45deg) translateY(-50%)
      .picScroll-left
        overflow: hidden
        position: relative
        .hd .next
          display: block
          width: 30px
          height: 86px
          position: absolute
          right: 35px
          top: 17px
          overflow: hidden
          cursor: pointer
          background: url(../images/next.png) no-repeat
          z-index: 10
        .hd .prev
          display: block
          width: 30px
          height: 86px
          position: absolute
          left: 35px
          top: 17px
          overflow: hidden
          cursor: pointer
          background: url(../images/prev.png) no-repeat
          z-index: 10
        .hd
          ul
            float: right
            overflow: hidden
            zoom: 1
            margin-top: 10px
            li
              float: left
              width: 9px
              height: 9px
              overflow: hidden
              margin-right: 5px
              text-indent: -999px
              cursor: pointer
              &.on
                background-position: 0 0
        .bd
          padding: 10px 60px
          ul
            overflow: hidden
            zoom: 1
            li
              margin: 0 5px
              float: left
              _display: inline
              overflow: hidden
              text-align: center
              position: relative
              .pic
                text-align: center
                img
                  width: 134px
                  height: 100px
                  display: block
                p
                  color: #fff
                  font-size: 14px
                  padding-top: 10px
                  overflow: hidden
                  white-space: nowrap
                  text-overflow: ellipsis
                  line-height: 24px

.tzz-tit-tab
  background: #484a50
  ul
    width: 1200px
    margin: 0 auto
    li
      width: 25%
      float: left
      text-align: center
      height: 65px
      line-height: 65px
      font-size: 16px
      color: #fff
      border-right: 1px solid #4e5057
      box-sizing: border-box
      &.active
        background: #626469
        color: #f0b475
.tzz-wrap
  .tab-wrap
    margin-top: 20px
    font-size: 0
    li
      display: inline-block
      position: relative
      width: 170px
      height: 40px
      line-height: 40px
      border-radius: 5px 5px 0px 0px
      color: #f3f3f3
      background-image: -moz-linear-gradient( -90deg, rgb(66,68,72) 0%, rgb(50,51,55) 100%)
      background-image: -webkit-linear-gradient( -90deg, rgb(66,68,72) 0%, rgb(50,51,55) 100%)
      background-image: -ms-linear-gradient( -90deg, rgb(66,68,72) 0%, rgb(50,51,55) 100%)
      text-align: center
      margin-right: 5px
      font-size: 16px
      &.active
        background-image: -moz-linear-gradient( 118deg, rgb(181,119,55) 0%, rgb(212,151,88) 100%)
        background-image: -webkit-linear-gradient( 118deg, rgb(181,119,55) 0%, rgb(212,151,88) 100%)
        background-image: -ms-linear-gradient( 118deg, rgb(181,119,55) 0%, rgb(212,151,88) 100%)
  .list-wrap
    background: #323337
    padding: 20px 40px
    .tit
      background: #38393d
      border-radius: 4px
      color: #cac9c9
      height: 40px
      line-height: 40px
      padding: 0 20px
      span
        position: relative
        padding-left: 10px
      span:after
        content: ""
        position: absolute
        background: #b57737
        width: 4px
        height: 18px
        border-radius: 4px
        left: 0px
        top: 2px
      a
        float: right
        padding-top: 3px
    ul
      width: 100%
      margin: 0px 0px 20px 0px
      overflow: hidden
      li + li
        border-top: 1px solid #424348
      li
        width: 100%
        position: relative
        height: 70px
        line-height: 70px
        position: relative
        display: flex
        border-bottom: 1px solid #2b2b2f
        font-size: 14px
        box-sizing: border-box
        a.t
          flex: 1
          color: #cac9c9
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
        span
          flex-basis: 100px
          color: #878787
          font-size: 16px
        a.d
          flex-basis: 120px
          cursor: pointer
          img
            display: block
            margin: 20px auto 0 auto
            vertical-align: top


.yw-wrap
  .title
    background: #323337
    padding: 0 40px 0 60px
    position: relative
    color: #f0b475
    line-height: 60px
    font-size: 17px
    margin-bottom: 2px
    &:after
      content: ""
      position: absolute
      left: 43px
      top: 26px
      width: 6px
      height: 6px
      border: 1px solid #f0b475
      transform: rotate(45deg)
    p
      float: right
      color: #cac9c9
      font-size: 14px
      a
        color: #cac9c9
  .title1
    background: #323337
    padding: 0 40px 0 60px
    position: relative
    color: #fff
    line-height: 60px
    font-size: 17px
    margin-bottom: 2px
    &:after
      content: ""
      position: absolute
      left: 43px
      top: 21px
      width: 4px
      height: 20px
      border-radius: 4px
      background: #e1a76a
    p
      float: right
      color: #cac9c9
      font-size: 14px
      a
        color: #cac9c9
  .cont_main
    padding: 30px 60px
    background: #323337
    .main_img
      display: inline-block
      position: relative
      border: 1px solid #4a433f
      span
        position: absolute
        left: -23px
        top: 10px
        display: inline-block
        padding: 0 20px
        background: #b28252
        height: 40px
        line-height: 40px
        z-index: 2
        color: #fff
        font-size: 18px
        &:after
          content: ""
          position: absolute
          left: 0px
          bottom: -14px
          border: 7px solid transparent
          border-top-color: #72502e
          border-right-color: #72502e
      img
        position: relative
        display: block
        left: -9px
        top: -9px
    .text
      padding: 20px 0 50px 0
      font-size: 15px
      line-height: 30px
      color: #b9b9b9
      .tip
        text-align: center
        font-size: 17px
        color: #fff
        padding-bottom: 10px
        line-height: 35px
      .more
        display: inline-block
        padding: 4px 15px
        font-size: 14px
        line-height: 20px
        cursor: pointer
        background: #3b3c41
        img
          margin-left: 7px
    .item-wrap
      display: flex
      background: url("../images/flow.jpg") repeat
      margin-bottom: 30px
      .v-img
        display: inline-block
        width: 383px
      .item-cont
        position: relative
        flex: 1
        padding: 10px 25px
        .txt
          color: #cac9c9
          h2
            font-size: 18px
            font-weight: normal
            padding-bottom: 10px
            margin-bottom: 10px
            position: relative
            &:after
              content: ""
              position: absolute
              left: 0px
              bottom: 0px
              width: 150px
              height: 1px
              background: #cac9c9
          p
            font-size: 14px
            line-height: 20px
        i.more
          width: 19px
          height: 19px
          position: absolute
          right: 20px
          cursor: pointer
          top: 50%
          transform: translateY(-50%)
          background: url("../images/icon8.png") no-repeat
    a.next-box
      display: block
      width: 100%
      text-align: center
      height: 60px
      line-height: 60px
      background: url("../images/flow.jpg") repeat
      color: #cac9c9
      font-size: 16px
    .bg-tit
      margin: 40px 0
      background: url("../images/yw-bg-tit.png") center center no-repeat
      font-size: 18px
      line-height: 52px
      text-align: center
      font-weight: bold
      color: #fff
    .p-txt
      padding: 0 30px
      margin-bottom: 20px
      h3
        position: relative
        color: #e9e9e9
        line-height: 35px
        font-size: 16px
        &:after
          content: ""
          position: absolute
          left: -20px
          top: 14px
          width: 8px
          height: 8px
          background: #946c43
          transform: rotate(45deg)
      p
        color: #bababa
        font-size: 15px
        line-height: 25px
    .ul-img
      display: flex
      padding: 20px 0
      img
        flex: 1
        padding: 0 15px
    .item-page
      margin-top: -30px
      img
        display: block
        margin: 0 auto
      .info
        width: 950px
        padding: 30px 50px
        background: rgba(255,255,255,0.8)
        position: relative
        display: flex
        left: 70px
        bottom: 70px
        span
          display: inline-block
          text-align: center
          width: 75px
          height: 75px
          background: #f0f0f0
          line-height: 75px
          font-size: 30px
        p
          flex: 1
          color: #555555
          font-size: 18px
          line-height: 25px
          padding: 10px 20px
    .ewm-box
      position: relative
      padding-bottom: 30px
      .bg
        display: block
        margin: 0 auto
      .ewm
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        .img
          padding: 8px
          border-radius: 10px
          background: #fff
          box-shadow: 0 0 10px rgba(0,0,0,0.4)
        p
          text-align: center
          color: #fff
          padding-top: 10px
          font-size: 16px
          line-height: 30px
    .fz-tit
      display: block
      margin: 10px auto 50px auto
      width: 299px
      height: 48px
      line-height: 48px
      color: #f0b475
      font-size: 24px
      text-align: center
      background: url("../images/infoPic36.png") no-repeat
    .fz-txt
      display: flex
      flex-wrap: wrap
      margin-bottom: 50px
      .img
        display: inline-block
        border: 1px solid #4a433f
        margin-right: 50px
        img
          position: relative
          left: -9px
          top: -9px
      .tp
        flex: 1
        line-height: 30px
        padding: 10px
        font-size: 15px
        color: #e5e5e5
        .p
          padding: 15px 0
        .t
          position: relative
          font-size: 18px
          padding-left: 20px
          color: #f0b475
          &:after
            content: ""
            position: absolute
            left: 0px
            top: 11px
            width: 6px
            height: 6px
            border: 1px solid #f0b475
            transform: rotate(45deg)
      .tab-box
        padding-top: 30px
        width: 100%
        .tab
          margin-bottom: 1px
          span
            display: inline-block
            padding: 0px 30px
            line-height: 40px
            color: #fff
            font-size: 15px
            border-top: 2px solid transparent
          span.active
            background: #38393d
            border-top: 2px solid #907356
        .b-box
          padding-bottom: 30px
          li
            display: none
            color: #b9b9b9
            min-height: 150px
            font-size: 15px
            line-height: 25px
            padding: 30px 20px
            background: #38393d
    .case-wrap:last-child:after
      display: none
    .case-wrap
      padding: 0 15px
      position: relative
      box-sizing: border-box
      &:after
        content: ""
        position: absolute
        left: 0px
        top: 12px
        width: 1px
        height: 100%
        border-left: 1px solid #4f4640
      .case-tit
        color: #f0b475
        line-height: 35px
        margin-bottom: 10px
        font-size: 18px
        position: relative
        &:after
          content: ""
          position: absolute
          left: -19px
          top: 12px
          width: 6px
          height: 6px
          background: #323337
          border: 1px solid #907356
          transform: rotate(45deg)
      .info-box
        display: flex
        padding-bottom: 25px
        img
          display: block
          margin-right: 50px
        .txt
          padding: 50px 0
          flex: 1
          color: #b9b9b9
          font-size: 15px
          line-height: 30px
      .tab-box
        .tab
          margin-bottom: 1px
          span
            display: inline-block
            padding: 0px 30px
            line-height: 40px
            color: #fff
            font-size: 15px
            border-top: 2px solid transparent
          span.active
            background: #38393d
            border-top: 2px solid #907356
        .b-box
          padding-bottom: 30px
          li
            display: none
            color: #b9b9b9
            min-height: 150px
            font-size: 15px
            line-height: 25px
            padding: 30px 20px
            background: #38393d
.search-wrap
  height: 274px
  background: url("../images/banner11.jpg") center center no-repeat
  .search-box
    width: 850px
    display: flex
    margin: 0 auto
    padding: 25px 0 10px 0
    .ipt
      flex: 1
      position: relative
      input
        width: 100%
        display: block
        height: 38px
        color: #898989
        line-height: 38px
        padding: 15px
        border: none
        height: 68px
        box-sizing: border-box
        background: #323337
      span
        position: absolute
        left: 0px
        top: 0px
        width: 100%
        color: #898989
        line-height: 68px
        font-size: 16px
        img
          display: inline-block
          vertical-align: top
          margin: 17px 15px 0 20px
    button
      width: 100px
      height: 68px
      line-height: 68px
      border: none
      background: #946c43
      color: #c0c0c0
      cursor: pointer
      font-size: 16px
      border-radius: 0 10px 10px 0
  .s-bot
    color: #898989
    font-size: 14px
    width: 800px
    padding: 10px 0
    margin: 0 auto
  ul
    width: 850px
    padding: 23px 0
    margin: 0 auto
    li
      float: left
      margin: 0 10px
      width: 261px
      height: 89px
      border-radius: 10px
      border: 1px solid transparent
      cursor: pointer
      box-sizing: border-box
      &:hover
        border: 1px solid #946c43
      &.l1
        background: url("../images/zp-img9.png") no-repeat
        span
          display: block
          height: 100%
          width: 100%
          background: url("../images/zp-img5.png") no-repeat
      &.l1:hover span
        background: url("../images/zp-img1.png") no-repeat

      &.l2
        background: url("../images/zp-img8.png") no-repeat
        span
          display: block
          height: 100%
          width: 100%
          background: url("../images/zp-img6.png") no-repeat
      &.l2:hover span
        background: url("../images/zp-img2.png") no-repeat
      &.l3
        background: url("../images/zp-img7.png") no-repeat
        span
          display: block
          height: 100%
          width: 100%
          background: url("../images/zp-img4.png") no-repeat
      &.l3:hover span
        background: url("../images/zp-img3.png") no-repeat
.zp-wrap
  overflow: hidden
  .mid-box
    border-top: 1px solid #63503d
    background: #323337
    .text
      width: 1050px
      min-height: 150px
      border-bottom: 1px solid #63503d
      margin: 0 auto
      p
        line-height: 24px
        font-size: 16px
        color: #fff
      .p
        padding: 5px 0
        width: 300px
        font-size: 16px
        color: #fff
        line-height: 24px
        display: flex
        span
          width: 90px
          text-align: right
        b
          color: #f0b475
          flex: 1
    .note
      width: 1050px
      margin: 0 auto
      padding: 20px 0
      line-height: 22px
      font-size: 14px
      color: #fff
    .jltd
      line-height: 50px
      text-align: center
      width: 185px
      cursor: pointer
      margin: 40px auto
      color: #fff
      font-size: 20px
      border-radius: 10px
      background-image: -moz-linear-gradient( -66deg, rgb(159,117,73) 0%, rgb(118,87,57) 100%)
      background-image: -webkit-linear-gradient( -66deg, rgb(159,117,73) 0%, rgb(118,87,57) 100%)
      background-image: -ms-linear-gradient( -66deg, rgb(159,117,73) 0%, rgb(118,87,57) 100%)
  .left-box
    width: 900px
    float: left
    .info-tit
      padding: 15px
      background: #323337
      margin-bottom: 1px
      .t
        display: flex
        .name
          flex: 1
          color: #f0b475
          font-size: 22px
        .price
          color: #cacaca
          font-size: 16px
          b
            color: #f0b475
            font-size: 20px
      .b
        display: flex
        dl
          margin: 0
          padding-top: 15px
          flex: 1
          dd
            border: 1px solid #535458
            border-radius: 5px
            color: #cacaca
            font-size: 15px
            line-height: 25px
            padding: 0 10px
            box-sizing: border-box
            margin: 0 5px 0 0
            float: left
        .date
          color: #8c8c8c
          line-height: 25px
          padding-top: 15px
          font-size: 14px
    .info-list
      background: #323337
      padding: 20px 30px
      .t
        position: relative
        font-size: 18px
        color: #f0b475
        padding-left: 10px
        margin-bottom: 15px
        &:after
          content: ""
          position: absolute
          left: 0px
          top: 3px
          width: 4px
          height: 16px
          border-radius: 4px
          background: #e1a76a
      .p-info
        font-size: 15px
        color: #bababa
        margin: 20px 0
        line-height: 25px
        p
          line-height: 30px
          color: #eaeaea
          font-size: 15px
      .wyyp
        line-height: 50px
        text-align: center
        width: 190px
        cursor: pointer
        margin: 40px auto
        color: #e9e9e9
        font-size: 18px
        border-radius: 4px
        background: #63503d
    .tit
      line-height: 50px
      padding: 0 20px
      color: #946c43
      font-size: 16px
      background: #323337
      margin-bottom: 1px
    ul
      li
        background: #323337
        padding: 15px 20px 10px 20px
        margin-bottom: 10px
        .top
          display: flex
          line-height: 30px
          .name
            flex: 1
            color: #f0b475
            font-size: 16px
            font-weight: bold
          .price
            width: 150px
            text-align: right
            color: #cacaca
            font-size: 14px
            b
              font-size: 16px
              color: #b78962
        .mid
          line-height: 22px
          padding-bottom: 15px
          p
            color: #bababa
            font-size: 14px
        .bot
          display: flex
          color: #8c8c8c
          font-size: 14px
          line-height: 30px
          .num
            flex: 1
          .date
            flex: 1
          .yp
            flex: 2
            text-align: right
            a
              display: inline-block
              text-align: center
              color: #bfbfbf
              height: 26px
              line-height: 26px
              width: 110px
              border: 1px solid #63503d
              &:hover
                background: #63503d
  .right-box
    width: 285px
    margin-left: 15px
    float: left
    .item-top
      background: #323337
      margin-bottom: 10px
      .h_tit
        background: #946c43
        line-height: 40px
        color: #fff
        text-align: center
        font-size: 16px
        text-indent: -15px
        span
          display: inline-block
          vertical-align: top
          margin-right: 15px
          margin-top: 13px
          width: 16px
          height: 16px
          background: url("../images/icon10.png") no-repeat
      ul
        padding: 10px 15px
        li + li
          border-top: 1px solid #4e5057
        li
          display: flex
          padding: 10px 0
          font-size: 14px
          line-height: 22px
          .txt
            flex: 1
            color: #bababa
            min-width: 0
            p
              text-overflow: ellipsis
              overflow: hidden
              white-space: nowrap
            b
              font-weight: normal
          .price
            text-align: right
            color: #f0b475
            width: 60px
    .item-mid
      background: #323337
      margin-bottom: 10px
      .h_tit
        background: url("../images/infoPic21.jpg") no-repeat
        line-height: 72px
        color: #fff
        text-align: center
        font-size: 16px
        text-indent: -15px
        span
          display: inline-block
          vertical-align: top
          margin-right: 15px
          margin-top: 24px
          width: 23px
          height: 24px
          background: url("../images/icon12.png") no-repeat
      .case
        padding: 20px
        .item
          text-align: center
          position: relative
          padding-bottom: 20px
          margin-bottom: 10px
          &:after
            content: ""
            position: absolute
            left: 50%
            margin-left: -15px
            bottom: 0px
            width: 30px
            height: 1px
            background: #946c43
          p
            color: #c1c1c1
            font-size: 14px
            line-height: 30px
          b
            display: block
            font-size: 16px
            color: #fff
        .ewm
          text-align: center
          position: relative

          p
            color: #c1c1c1
            font-size: 14px
            line-height: 30px
            margin-bottom: 5px
    .item-bot
      background: #323337
      padding: 20px
      color: #c1c1c1
      font-size: 14px
      .img
        overflow: hidden
        img
          width: 50%
          float: left
.flow-box
  position: fixed
  width: 80px
  background: rgba(0,0,0,0.4)
  right: calc((100% - 1200px)/2)
  border-radius: 5px
  top: 30%
  overflow: hidden
  ul
    li
      text-align: center
      padding: 10px 0
      cursor: pointer
      img
        padding: 6px 0
      p
        color: #eaeaea
        font-size: 15px
  .top
    background: rgba(0,0,0,0.4)
    text-align: center
    font-size: 14px
    color: #eaeaea
    cursor: pointer
    padding: 5px 0
    img
      display: inline-block
      vertical-align: top
      margin-top: 2px
      margin-left: 4px